<template>
  <div id="accordion" :class="{ open: open }">
    <div class="header-part d-flex justify-content-between align-items-center" @click="toggleContent">
      <div class="title">
        <slot name="header"></slot>
      </div>
      <div class="toggle-icon">
        <span :class="[{ open: open }, iconClass]" class="toggle-arrow" />
        <!-- <i
          class="toggle-arrow bb-arrow-down"
          :class="[{ open: open }, iconClass]"
        ></i> -->
      </div>
    </div>
    <div class="content-part" ref="content-part" v-if="contentIsOpened">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    maxHeight: {
      type: [Number, String],
      default: 340,
    },
    iconClass: {
      type: String,
    },
  },
  components: {},
  data: () => ({
    contentIsOpened: false,
    open: false,
  }),
  created() {},
  computed: {},
  methods: {
    toggleContent() {
      this.open = !this.open;
      if (this.contentIsOpened) {
        this.closeContent();
      } else {
        this.openContent();
      }
    },
    openContent() {
      this.contentIsOpened = true;
      setTimeout(() => {
        this.$anime.timeline().add({
          targets: this.$refs["content-part"],
          maxHeight: this.maxHeight,
          easing: "easeOutExpo",
          duration: 400,
        });
      }, 100);
    },
    closeContent() {
      this.$anime.timeline().add({
        targets: this.$refs["content-part"],
        maxHeight: 0,
        easing: "easeOutExpo",
        duration: 400,
        complete: () => {
          this.contentIsOpened = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$animation-speed: 0.4s;
#accordion {
  .header-part {
    cursor: pointer;

    .title,
    .toggle-icon {
      cursor: pointer;
      .toggle-arrow {
        display: inline-block;
        width: 20px;
        height: 10px;
        background: #6C727F;
        transition: $transition-global;
        cursor: pointer;
        mask-image: url("/images/icons/bb-icon_arrow-down.svg");
        mask-repeat: no-repeat;
        mask-size: contain;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .content-part {
    // transition: all 0.4s ease-in-out;
    overflow: hidden;
    max-height: 0;
  }
}
</style>
